<template>
    <div>
        <div class="text-center">
            <h1>Administration</h1>
        </div>

        <div role="tablist">
            <mobile-admin-user-tab/>
            <mobile-admin-emprunts-tab/>
            <mobile-admin-local-tab/>
            <mobile-admin-evenements-tab/>
        </div>
    </div>
</template>

<script>
    const MobileAdminLocalTab      = () => import('@/components/mobile_app/MobileAdminLocalTab');
    const MobileAdminUserTab       = () => import('@/components/mobile_app/MobileAdminUserTab');
    const MobileAdminEmpruntsTab   = () => import('@/components/mobile_app/MobileAdminEmpruntsTab');
    const MobileAdminEvenementsTab = () => import('@/components/mobile_app/MobileAdminEvenementsTab');

    export default {
        name: "MobileAdmin",
        components: {MobileAdminLocalTab, MobileAdminEmpruntsTab, MobileAdminUserTab, MobileAdminEvenementsTab}
    }
</script>
